/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */

$base-color: #00c59b;
$theme-color: #0c59db;
$lg: "only screen and (min-width: 992px) and (max-width: 1200px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs: "(max-width: 767px)";
$sm: "only screen and (min-width: 576px) and (max-width: 767px)";

html,
body {
	height: 100vh;
}
* {
	font-family: "Nunito Sans";
}

/** Shadow for skeleton reference */
.shorder {
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.75);
}
.section {
	min-height: 300px;
}
.headfoot {
	height: 110px;
}
.navLogo {
	padding: 5px;
	height: 65px;
}
.navLink {
	padding: 1rem;
}
.nav-link-mobile {
	display: none;
}
.header {
	position: sticky;
	top: 0px;
	box-shadow: 0px 0px 50px 20px rgba(238, 246, 248, 0.5);
	background-color: white;
	z-index: 100;
}
.btn-sign-up {
	border: 2px solid #00c59b !important;
	border-radius: 15px;
	padding: 10px;
	width: 120px;
	text-align: center;
}

$a-tags: "a, a:active, a:hover, a:visited";
$a-tags-hover: "a:active, a:hover";

#{$a-tags} {
	color: black;
	text-decoration: none;
}
#{$a-tags-hover} {
	color: blue;
}

.segment2 {
	padding-top: 50px;
	box-shadow: inset 0 -10px 10px -20px #0aaea1;
	background: #0aaea1;
	background: radial-gradient(ellipse at bottom, #0aaea1 1%, white 45%);
}

.segment3 {
	padding-top: 50px;
}

h1.main-text {
	font-size: 3.5rem;
	color: #00c59a;
	font-weight: 700;
	text-align: center;
}
h1.main-text-white {
	font-size: 3rem;
	color: #fff;
	text-align: center;
}
h1.segment7-main-text-white {
	font-size: 2.5rem;
	color: #fff;
	text-align: center;
}
h2.main-text {
	color: #00c59a;
	text-align: center;
}
h4.main-text {
	margin-top: 1.8rem;
	margin-bottom: 0;
}
h4.segment5-main-text {
	font-size: 1rem;
	margin-top: 1.8rem;
	margin-bottom: 0;
	color: #fff;
}
span.sub-text,
p.sub-text {
	color: #68a3aa;
	text-align: center;
	padding: 15px;
}
span.sub-text-white,
p.sub-text-white {
	font-size: 1.2rem;
	color: #fff;
	padding: 20px;
	text-align: center;
}
span.segment7-sub-text,
p.segment7-sub-text {
	font-size: 1.2rem;
	color: #cde8ee;
}

.segment6-main-text {
	margin-top: 2.3em;
	font-size: 3rem;
	color: #017e80;
	text-align: center;
}
.segment8-main-text {
	margin-top: 3em;
	font-size: 2.25rem;
	color: #017e80;
	text-align: center;
}

.btn-be-our-partner {
	color: #fff;
	background-color: #00c59b;
	border-color: #00c59b;
	border-radius: 10px;

	&:hover {
		position: relative;
		top: 2px;
		color: #fff;
		background-color: #00c59b;
		border-color: #00c59b;
	}
}

.segment4 {
	background: #fff;
	padding: 20px;
	border-radius: 30px;
	box-shadow: 0px 0px 16px 0px rgba(0, 197, 155, 0.15);
}

.icon-box {
	color: #68a3aa;
	text-align: center;
	background: #fff;
	padding: 30px 30px;
	transition: all ease-in-out 0.3s;
}

.icon {
	width: 180px;
}
.segment5 {
	margin-top: -7rem;
	padding-top: 9rem;
}

.segment5-div,
.segment7-div {
	padding-top: 100px;
	padding-bottom: 60px;
}
.segment5-div {
	padding-bottom: 120px;
}

.segment9-div {
	padding-top: 50px;
	min-height: 150px;
}

.segment5-icon-box {
	text-align: center;
	padding: 20px;
	transition: all ease-in-out 0.3s;
}

.segment5-icon {
	width: 125px;
}

.segment5,
.segment7,
.segment9 {
	background: #0179c6;
	background: linear-gradient(90deg, #0179c6 10%, #0aaea1 90%);
}

.segment6-image-1 {
	max-width: 350px;
}
.segment6-image-3 {
	max-width: 350px;
}

.text-transparent {
	color: rgba(0, 0, 0, 0);
}

.card-title {
	font-size: 3rem;
	color: #00c59a;
}

.card-text {
	color: #017e80;
}

/*===========================
    7.FAQ css 
===========================*/
.faq-area {
	padding-bottom: 120px;
}
.faq-area .section-title .title {
	padding-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.faq-area .section-title .title {
		font-size: 56px;
	}
}
@media (max-width: 767px) {
	.faq-area .section-title .title {
		font-size: 34px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.faq-area .section-title .title {
		font-size: 54px;
	}
}
.faq-area .section-title .title::before {
	content: "faq";
	left: -4px;
	transform: translateX(0%);
	bottom: 56px;
}
.faq-area .faq-accordion.accordion {
	border: 2px solid #e3eeff;
}
.faq-area .faq-accordion .accordion .card {
	border-radius: 0;
	border: 0;
	border-top: 2px solid #e3eeff;
}
.faq-area .faq-accordion .accordion .card:first-child {
	border-top: 0;
}
.faq-area .faq-accordion .accordion .card .card-header {
	border-bottom: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
}
.faq-area .faq-accordion .accordion .card .card-header .btn-link {
	line-height: 60px;
	padding: 0 0 0 40px;
	text-decoration: none;
	font-weight: 700;
	color: #002249;
	font-size: 16px;
	width: 100%;
	color: #0c59db;
	position: relative;
}
@media (max-width: 767px) {
	.faq-area .faq-accordion .accordion .card .card-header .btn-link {
		padding: 10px 30px 10px 20px;
		line-height: 20px;
		font-size: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.faq-area .faq-accordion .accordion .card .card-header .btn-link {
		padding: 0 0 0 40px;
		line-height: 60px;
		font-size: 16px;
	}
}
.faq-area .faq-accordion .accordion .card .card-header .btn-link.collapsed {
	color: #002249;
}
.faq-area .faq-accordion .accordion .card .card-header .btn-link.collapsed::before {
	content: "\f063";
	color: #cddae8;
}
.faq-area .faq-accordion .accordion .card .card-header .btn-link::before {
	position: absolute;
	content: "\f062";
	right: 36px;
	font-family: "Font Awesome 5 Pro";
}
@media (max-width: 767px) {
	.faq-area .faq-accordion .accordion .card .card-header .btn-link::before {
		right: 18px;
	}
}
.faq-area .faq-accordion .accordion .card .card-body {
	padding: 10px 40px 14px 40px !important;
}
@media (max-width: 767px) {
	.faq-area .faq-accordion .accordion .card .card-body {
		padding: 0 40px 14px 20px !important;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.faq-area .faq-accordion .accordion .card .card-body {
		padding: 0 40px 14px 40px !important;
	}
}
.faq-area .faq-accordion .accordion .card .card-body p {
	margin-top: -10px;
	font-size: 14px;
}
.faq-area .faq-accordion .accordion .card.show .card-body {
	padding: 0 40px 14px 40px !important;
}
.faq-area .faq-accordion .accordion .card.show .card-body p {
	margin-top: -10px;
	font-size: 14px;
}
.faq-area .single-faq {
	border: 2px solid #e3eeff;
	padding: 34px 38px;
	position: relative;
	z-index: 10;
	overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.faq-area .single-faq {
		padding: 34px 20px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.faq-area .single-faq {
		margin-top: 40px;
	}
}
@media (max-width: 767px) {
	.faq-area .single-faq {
		margin-top: 40px;
	}
}
.faq-area .single-faq .title {
	color: #0c59db;
	font-size: 16px;
}
.faq-area .single-faq .title span {
	font-size: 50px;
}
.faq-area .single-faq > span {
	font-size: 14px;
	font-weight: 700;
}
.faq-area .single-faq p {
	font-size: 14px;
	padding-top: 15px;
}
.faq-area .single-faq i {
	font-size: 120px;
	position: absolute;
	top: 10px;
	right: 15px;
	color: #f6fbff;
	z-index: -1;
}
.faq-area.faq-area-3 .section-title .title::before {
	display: none;
}
.faq-area.faq-area-3 .faq-thumb {
	margin-top: 35px;
}
.faq-area.faq-area-3 .faq-thumb .faq-thumb-1 {
	padding-right: 40px;
	position: relative;
}
.faq-area.faq-area-3 .faq-thumb .faq-thumb-1::before {
	position: absolute;
	content: "";
	height: 90px;
	width: 90px;
	border-radius: 50%;
	background: #edf4ff;
	right: -6px;
	bottom: -43px;
	z-index: -1;
}
.faq-area.faq-area-3 .faq-thumb .faq-thumb-2 {
	margin-top: -220px;
	position: relative;
	z-index: 5;
}
.faq-area.faq-area-3 .faq-thumb .faq-thumb-2 img {
	border: 15px solid #f5faff;
}
/*===========================
    16.ANSWERS css 
===========================*/
.faq-accordion-2 .accordion {
	border: 2px solid transparent;
}
.faq-accordion-2 .accordion .card {
	border-radius: 0;
	border: 0;
	border-top: 2px solid transparent;
	margin-bottom: 20px;
	box-shadow: 0px 8px 16px 0px rgba(132, 190, 255, 0.1);
}
.faq-accordion-2 .accordion .card:first-child {
	border-top: 0;
}
.faq-accordion-2 .accordion .card .card-header {
	border-bottom: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
}
.faq-accordion-2 .accordion .card .card-header .btn-link {
	line-height: 80px;
	padding: 0 0 0 40px;
	text-decoration: none;
	font-weight: 700;
	font-size: 16px;
	width: 100%;
	color: #002249;
	position: relative;
}
@media (max-width: 767px) {
	.faq-accordion-2 .accordion .card .card-header .btn-link {
		padding: 10px 30px 10px 20px;
		line-height: 20px;
		font-size: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.faq-accordion-2 .accordion .card .card-header .btn-link {
		padding: 0 0 0 40px;
		line-height: 60px;
		font-size: 16px;
	}
}
.faq-accordion-2 .accordion .card .card-header .btn-link.collapsed {
	color: #7a95b4;
}
.faq-accordion-2 .accordion .card .card-header .btn-link.collapsed::before {
	content: "\f107";
	color: #cddae8;
}
.faq-accordion-2 .accordion .card .card-header .btn-link::before {
	position: absolute;
	content: "\f106";
	right: 36px;
	font-family: "Font Awesome 5 Pro";
}
@media (max-width: 767px) {
	.faq-accordion-2 .accordion .card .card-header .btn-link::before {
		right: 18px;
	}
}
.faq-accordion-2 .accordion .card .card-body {
	padding: 0 40px 14px 40px !important;
}
@media (max-width: 767px) {
	.faq-accordion-2 .accordion .card .card-body {
		padding: 0 40px 14px 20px !important;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.faq-accordion-2 .accordion .card .card-body {
		padding: 0 40px 14px 40px !important;
	}
}
.faq-accordion-2 .accordion .card .card-body p {
	margin-top: -10px;
	font-size: 14px;
}
.faq-accordion-2 .accordion .card.show .card-body {
	padding: 0 40px 14px 40px !important;
}
.faq-accordion-2 .accordion .card.show .card-body p {
	margin-top: -10px;
	font-size: 14px;
}
.asnwers-area .section-title-2 p {
	padding-right: 42px;
}
.asnwers-area .answers-thumb {
	margin-top: 100px;
}
.asnwers-area .answers-thumb .answers-thumb-2 {
	margin-top: -220px;
}
.asnwers-area .answers-thumb .answers-thumb-2 img {
	border: 15px solid #f5faff;
}
.about-faq-area {
	padding-top: 113px;
}
.about-faq-area .faq-video-thumb {
	position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.about-faq-area .faq-video-thumb img {
		width: 100%;
	}
}
@media (max-width: 767px) {
	.about-faq-area .faq-video-thumb img {
		width: 100%;
	}
}
.about-faq-area .faq-video-thumb a {
	position: absolute;
	left: 45%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 120px;
	width: 120px;
	border-radius: 50%;
	text-align: center;
	line-height: 120px;
	background: #0c59db;
	color: #fff;
	font-size: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.about-faq-area .faq-video-thumb a {
		left: 50%;
	}
}
@media (max-width: 767px) {
	.about-faq-area .faq-video-thumb a {
		left: 50%;
	}
}
.about-faq-area .about-faq-content span {
	font-size: 16px;
	color: #0c59db;
	font-weight: 700;
	position: relative;
}
.about-faq-area .about-faq-content span::before {
	position: absolute;
	content: "";
	height: 2px;
	width: 70px;
	background: #0c59db;
	right: -100px;
	top: 10px;
}
.about-faq-area .about-faq-content .title {
	font-size: 60px;
	line-height: 70px;
	padding-top: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.about-faq-area .about-faq-content .title {
		font-size: 52px;
		line-height: 62px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.about-faq-area .about-faq-content .title {
		padding-right: 170px;
	}
}
@media (max-width: 767px) {
	.about-faq-area .about-faq-content .title {
		font-size: 32px;
		line-height: 42px;
	}
}
.about-faq-area.about-faq-area-page .faq-video-thumb-area .faq-video-thumb-1 {
	margin-bottom: -210px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.about-faq-area.about-faq-area-page .faq-video-thumb-area .faq-video-thumb-1 {
		margin-bottom: 30px;
	}
}
@media (max-width: 767px) {
	.about-faq-area.about-faq-area-page .faq-video-thumb-area .faq-video-thumb-1 {
		margin-bottom: 30px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.about-faq-area.about-faq-area-page .faq-video-thumb-area .faq-video-thumb-1 img {
		width: 100%;
	}
}
@media (max-width: 767px) {
	.about-faq-area.about-faq-area-page .faq-video-thumb-area .faq-video-thumb-1 img {
		width: 100%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.about-faq-area.about-faq-area-page .faq-video-thumb-area .faq-video-thumb-2 img {
		width: 100%;
	}
}
@media (max-width: 767px) {
	.about-faq-area.about-faq-area-page .faq-video-thumb-area .faq-video-thumb-2 img {
		width: 100%;
	}
}
.about-faq-area.about-faq-area-page .faq-accordion-3 .accordion .card {
	border-radius: 0 !important;
	border: 2px solid #e3eeff;
	box-shadow: none;
}
.faq-accordion-3 {
	padding-top: 35px;
}
.faq-accordion-3 .accordion {
	border: 2px solid transparent;
}

.faq-accordion-3 .accordion .accordion-button {
    &:after {
        content: none;
    }
    &:not(.collapsed) {
        background: transparent;
    }
    &:focus {
        box-shadow: none;
    }
}

.faq-accordion-3 .accordion .accordion-item {
	border-radius: 40px !important;
	border: 0;
	border-top: 2px solid transparent;
	margin-bottom: 20px;
	box-shadow: 0px 0px 16px 0px rgba(0, 197, 155, 0.15);
}
.faq-accordion-3 .accordion .accordion-item:first-child {
	border-top: 0;
}
.faq-accordion-3 .accordion .accordion-item .accordion-header {
	border-bottom: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
}
.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button {
	line-height: 80px;
	padding: 0 0 0 40px;
	text-decoration: none;
	font-weight: 700;
	font-size: 16px;
	width: 100%;
	color: #007e80;
	position: relative;
	text-align: left;
    border-radius: 40px;

    &:after {
        content: none;
    }
    &:not(.collapsed) {
        background: transparent;
    }
    &:focus {
        box-shadow: none;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button {
		font-size: 14px;
	}
}
.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button i {
	color: #0c59db;
	padding-right: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button i {
		padding-right: 10px;
	}
}
@media (max-width: 767px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button {
		padding: 10px 30px 10px 20px;
		line-height: 20px;
		font-size: 15px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button {
		padding: 0 0 0 40px;
		line-height: 60px;
		font-size: 16px;
	}
}
.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button.collapsed {
	font-size: 16px;
	color: #007e80;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button.collapsed {
		padding: 0 0 0 20px;
		font-size: 14px;
	}
}
.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button.collapsed::before {
	content: "\f067";
	color: #99b1c8;
}
.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button::before {
	position: absolute;
	content: "\f068";
	right: 36px;
	font-size: 16px;
	font-family: "Font Awesome 5 Pro";
	color: #99b1c8;
}
.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button::selection {
	background-color: transparent;
}
@media (max-width: 767px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-header .accordion-button::before {
		right: 18px;
	}
}
.faq-accordion-3 .accordion .accordion-item .accordion-body {
	padding: 10px 30px 14px 40px !important;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-body {
		padding: 0 30px 14px 45px !important;
	}
}
@media (max-width: 767px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-body {
		padding: 10px 40px 20px 20px !important;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.faq-accordion-3 .accordion .accordion-item .accordion-body {
		padding: 0 40px 14px 40px !important;
	}
}
.faq-accordion-3 .accordion .accordion-item .accordion-body p {
	padding-top: 15px;
	margin-top: -10px;
	font-size: 14px;
	color: #007e80;
}
.faq-accordion-3 .accordion .card.show .accordion-body {
	padding: 0 40px 14px 40px !important;
}
.faq-accordion-3 .accordion .card.show .accordion-body p {
	margin-top: -10px;
	font-size: 14px;
}
.faq-accordion-3 .accordion .card .card-header .btn-link,
.faq-area .faq-accordion .accordion .card .card-header .btn-link,
.faq-accordion-2 .accordion .card .card-header .btn-link,
.our-choose-area .accordion .card .card-header .btn-link {
	text-align: left;
	border: none;
	border-radius: 0;
}
.faq-accordion-3 .accordion .card .card-header .btn-link:focus,
.faq-area .faq-accordion .accordion .card .card-header .btn-link:focus,
.faq-accordion-2 .accordion .card .card-header .btn-link:focus,
.our-choose-area .accordion .card .card-header .btn-link:focus {
	box-shadow: none;
}

@media (max-width: 1440px) {
	.image-segment2 {
		width: 500px;
		max-width: 100%;
	}
	.image-segment3 {
		width: 850px;
	}
	.segment6-image {
		width: 350px;
		max-width: 100%;
	}
}
@media (max-width: 1199px) {
	.image-segment2 {
		width: 550px;
	}
	.image-segment3 {
		width: 800px;
		max-width: 100%;
	}
}
.verticalMid {
	vertical-align: middle;
	margin: auto;
	text-align: center;
}
/* BURGER BAR */
.main-nav {
    padding: 8px 16px;
	display: flex;
	height: 110px;
	justify-content: space-between;
	align-items: center;
	color: #000;
	background: #fff;
}
.logo {
	letter-spacing: 3px;
	text-transform: uppercase;
	font-size: 1.25rem;
	padding: 0 2rem;
}
.nav-links {
	display: flex;
	// width: 40%;
	// justify-content: flex-end;
	align-items: center;
	p {
		margin-left: 1rem;
	}
}
.burger {
	display: none;
	padding: 0.5rem;
	cursor: pointer;
}
.line {
	height: 2px;
	width: 35px;
	margin: 0.5rem 0;
	margin-right: 15px;
	background: #00c59b;
	transition: transform 0.75s ease, opacity 0.75s ease;
}

.line-white {
	background: #fff;
}
@media screen and (max-width: 800px) {
	.nav-links {
		width: 60%;
	}
}
.main-nav.show .line1 {
	transform: translate(-10px) rotate(45deg) translate(15px);
}
.main-nav.show .line2 {
	opacity: 0;
}
.main-nav.show .line3 {
	transform: translate(-10px) rotate(-45deg) translate(15px);
}
.main-nav.show .nav-links {
	transform: translateX(0%);
}
@media screen and (max-width: 800px) {
	.nav-links {
		width: 60%;
		z-index: 2;
	}
}
@media screen and (max-width: 600px) {
	.burger {
		display: block;
	}
	.nav-links {
		position: fixed;
		top: 0px;
		right: 0;
		height: 100%;
		width: 90%;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		background: #0179c6;
		background: linear-gradient(90deg, #0179c6 10%, #0aaea1 90%);
		transform: translateX(100%);
		transition: transform 1s ease;
	}
	.navLink {
		display: none;
	}
	.nav-link-mobile {
		display: flex;
		width: 100%;
		height: 100%;
		flex-flow: column;
		padding: 30px 30px 35px 30px;
		color: #fff;
		.menu-content {
			display: flex;
			flex-flow: column;
			flex: 1;
			.burger {
				margin-bottom: 47px;
			}
			img {
				width: 133px;
				margin-bottom: 60px;
			}
			.menu-subtext {
				margin-bottom: 30px;
			}
			.menu-btn {
				display: flex;
				flex-flow: column;
				a {
					padding: 30px 0px;
					color: #fff;
					font-size: 16px;
					display: flex;
					align-items: center;
					i {
						font-size: 20px;
						margin-right: 20px;
					}
				}
				a:nth-child(2) {
					border-top: 1px solid #fff;
				}
			}
		}
		.menu-footer {
			display: flex;
			align-items: center;
			img {
				width: 45px;
				margin-right: 35px;
			}
		}
		.menu-subtext {
			font-size: 14px;
			letter-spacing: -0.28px;
		}
	}
	.main-nav {
		height: 80px;
	}
}
/* BURGER BAR */
/* MOBILE RESPONSIVE */
@media screen and (max-width: 600px) {
	.btn-sign-up {
		border: none !important;
		color: #00c59b !important;
		font-weight: 600;
	}
	.logo,
	.burger {
		padding: 0;
	}
	/* .segment4 {	
	  margin-right: 25px;	
	  margin-left: 25px;	
	} */
	.container.position-relative.segment4 {
		max-width: 90%;
	}
	.icon-box {
		padding: 0px 30px;
	}
	.segment6-image {
		max-width: 250px;
		width: 200px;
	}
	.card-title {
		font-size: 35px;
	}
}

@media (max-width: 480px) {
	.navLogo {
		height: 50px;
	}
	.headfoot {
		height: 70px;
	}
	.card-title {
		font-size: 30px;
	}
	.segment6-image-2 {
		max-width: 150px;
	}
}

@media (max-width: 375px) {
	.card-title {
		font-size: 20px;
	}
	.segment6-image-2 {
		max-width: 150px;
	}
	.card-text {
		font-size: 15px;
	}
	.segment6-image-1 {
		max-width: 180px;
	}
	.segment6-image-3 {
		max-width: 175px;
	}
}

/** Rotate animation: Retrieved from https://animista.net/play/basic/rotate*/
.rotate-center {
	-webkit-animation: rotate-center 2s linear infinite both;
	animation: rotate-center 2s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-9 12:26:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/** End Rotate animation */

/** Nunito Sans Font Setup */
@font-face {
	font-family: "Nunito Sans";
	src: url("./assets/fonts/Nunito_Sans/NunitoSans-ExtraLight.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: "Nunito Sans";
	src: url("./assets/fonts/Nunito_Sans/NunitoSans-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "Nunito Sans";
	src: url("./assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Nunito Sans";
	src: url("./assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "Nunito Sans";
	src: url("./assets/fonts/Nunito_Sans/NunitoSans-ExtraBold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: "Nunito Sans";
	src: url("./assets/fonts/Nunito_Sans/NunitoSans-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}
/** END: Nunito Sans Font Setup */
